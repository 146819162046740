import './App.css';
import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in';
import pre from './img/pre.gif'
import post from './img/post.gif'
import kiss from './img/kiss.png'

function App() {

  const [clickedYes, setClickedYes] = useState(false)
  const [showNoButton, setShowNoButton] = useState(true)
  const [currentWord, setCurrentWord] = useState('')
  const [count, setCount] = useState(0)
  const [fontSize, setFontSize] = useState(0)

  useEffect(() => {
      updateNoButton()
  }, [])

  const updateNoButton = () => {
    const words = ['No', 'Are you sure?', 'Pretty please??', 'Are you really sure 🥺', 'But Sammy 🥺🥺🥺', 'What if I ask with a cute emoji? 🦉', "I'm gonna cry 😭", 'WAHHHHH', 
                  "FINE I won't ask anymore..", 'JUST KIDDING. PLEASE say yes', 'But I love you 🥺', 'SAM PLEASE', 'BABY 🥺😭', 'Last Chance.', 'null']
    
    setFontSize(fontSize + 12)
    setCurrentWord(words[count])
    setCount(count + 1)
    if (count >= 14) {
      setShowNoButton(false)
      setCount(0)
      setFontSize(25)
    }
  }

  const updateYes = () => {
    setClickedYes(true)
  }

  return (
    <div className= 'App'>
      {clickedYes ?
                  <FadeIn>  
                    <div className= 'centering-container'>
                      <div className= 'main-container'>
                        <img src= {post}></img>
                        <h2>OK YAY!!! 💕</h2>
                        <img className= 'kiss' src= {kiss}></img>
                      </div>
                    </div>
                  </FadeIn>
                  :
                  <FadeIn>  
                    <div className= 'centering-container'>
                      <div className= 'main-container'>
                        <img src= {pre}></img>
                        <h2>Will you be my Valentine?</h2>
                        <button className= 'yes-button' style={{'font-size': fontSize}} onClick={() => updateYes()}>Yes!</button>
                        {showNoButton ? 
                                      <button className= 'no-button' onClick={() => updateNoButton()}>{currentWord}</button> 
                                      :
                                      <strong>Oh nooo, looks like you only have 1 option now :p</strong>}
                      </div>
                    </div>
                  </FadeIn>
      }
    </div>
  );
}

export default App;

